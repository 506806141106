import { Helmet } from "react-helmet";

import bo from "images/bo.png";

import locateIcon from "images/locate.png";
import phoneIcon from "images/phone.png";
import emailIcon from "images/email.png";
import facebookIcon from "images/facebook.png";
import instagramIcon from "images/instagram.png";
import { Link } from "react-router-dom";

const Contact = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Contact - Bières de Ré</title>
            </Helmet>

            <div className="bg-[#cfedff] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16">
                    <Link to="/" className="underline cursor-pointer font-[500]">Accueil</Link>
                    <span className="ml-2 mr-2">{">"}</span>
                    <h1>Nous contacter</h1>
                </div>
            </div>


            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-8">
                            <div className="flex w-full">
                                <img className="flex w-full" src={bo} />
                            </div>

                            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2">
                                <div className="bg-[#f9f5ef] px-4 py-4">
                                    <h1 className="text-[32px] text-[#7c7d7d] leading-[45px]">Contacter le service consommateurs</h1>
                                    <div className="mt-6 mb-6">
                                        <p className="text-[#7c7d7d] text-[14px] leading-[20px]">Une question ? Contactez-nous via le formulaire ci-dessous. Nous vous répondrons dans les meilleurs délais</p>
                                    </div>

                                    <div className="text-[14px] text—[#555]">
                                        <div className="flex flex-row">
                                            <img className="h-4 w-4 relative top-[.6rem] mr-1" src={locateIcon} />
                                            <h1 className="ml-1">RD 201- Les hauts des Peux Bertaud 17740 Sainte Marie de Ré</h1>
                                        </div>
                                        <div className="flex flex-row">
                                            <img className="h-4 w-4 relative top-[.6rem] mr-1" src={phoneIcon} />
                                            <h1 className="ml-1">05 46 43 82 63 (service consommateurs)</h1>
                                        </div>
                                        <div className="flex flex-row">
                                            <img className="h-4 w-4 relative top-[.6rem] mr-1" src={emailIcon} />
                                            <h1 className="ml-1">contact@bieresdere.fr</h1>
                                        </div>
                                        <div className="flex flex-row">
                                            <img className="h-4 w-4 relative top-[.6rem] mr-1" src={facebookIcon} />
                                            <h1 className="ml-1">bieresdere</h1>
                                        </div>
                                        <div className="flex flex-row">
                                            <img className="h-4 w-4 relative top-[.6rem] mr-1" src={instagramIcon} />
                                            <h1 className="ml-1">bieresdere</h1>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="bg-[white] px-4 py-4">
                                        <h1 className="text-[32px] text-[#7c7d7d] leading-[45px]">Vous rendre à la boutique à Sainte-Marie de Ré</h1>
                                        <div className="mt-6 mb-6">
                                            <p className="text-[#7c7d7d] text-[14px] leading-[20px]">Vous êtes sur l'île de Ré et vous avez envie de déguster une bière artisanale ou d'autres boissons de qualité ?</p>
                                            <p className="text-[#7c7d7d] text-[14px] leading-[20px] mt-4">Venez nous rendre visite en boutique à Ste-Marie-de-Ré : nous vous proposons un large choix de produits locaux pour désaltérer petits et grands : bières artisanales, spiritueux et même des sodas.</p>
                                            <p className="text-[#7c7d7d] text-[14px] leading-[20px] mt-4">Nous vous accueillons dans notre boutique où les bières sont brassées sous vos yeux !</p>
                                        </div>

                                        <div className="text-center text-[14px]">
                                            <h1 className="font-[700]">BASSE SAISON</h1>
                                            <p className="leading-[25px]">(octobre à mars)<br />du lundi au vendredi<br />10h/12h30 - 14h/17h00<br />le samedi pendant les vacances scolaires</p>
                                        </div>
                                        <div className="text-center text-[14px] mt-6">
                                            <h1 className="font-[700]">HAUTE SAISON</h1>
                                            <p className="leading-[25px]">(avril à septembre)<br />du lundi au vendredi<br />10h/13h - 14h30/18h30 (19h en juillet et août).</p>
                                        </div>
                                        <h1 className="font-[700] text-[14px] text-center mt-6">Fermeture annuelle en Janvier</h1>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* BEFORE FOOTER  */}
            <div className="cover-background">
                <div className={`container h-[590px]`}>
                    <div className="py-10 flex justify-center w-full h-full lg:px-36">
                        <iframe width={'100%'} height={'500px'} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5527.313283095267!2d-1.324288!3d46.157583!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4803fef226a06283%3A0xd8f770076e8b094c!2zQmnDqHJlcyBkZSBSw6k!5e0!3m2!1sfr!2sus!4v1689597358911!5m2!1sfr!2sus" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
            {/* BEFORE FOOTER  */}

        </div>
    );
}

export default Contact;
