import api from "../api/token";

// CHECK VALIDITY TOKEN
export const checkTokenValidity = (token: string): any => {

    if (token !== '') {
        return api
            .checkTokenValidity(token)
            .then(async (result: any) => {
                if (result) {
                    if (result.data === false) {
                        localStorage.removeItem('token');
                        localStorage.clear()
                    }

                    return result
                }
            })
    }
};