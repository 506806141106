import React from "react";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import mastercardImg from "images/mastercard.svg";
import visaImg from "images/visa.svg";
import stripeImg from "images/stripe.svg";
import { COLORS } from "config/theme";
import { useHistory } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "BIERES DE RE",
    menus: [
      { href: "#", label: "Local et authentique" },
      { href: "#", label: "RD 201 LES HAUTS DES PEUX BERTAUD" },
      { href: "#", label: "17740 - SAINTE MARIE DE RE" },
      { href: "#", label: "Service consommateur: 05 46 43 82 63" },
      { href: "mailto:contact@bieresdere.fr", label: "contact@bieresdere.fr" },
    ],
  },
  {
    id: "2",
    title: "Nos catégories",
    menus: [
      { href: "/products/", label: "Bières", id: '64a7fdc313b323219b169268' },
      { href: "/products/", label: "Spiritueux", id: '64a7fde813b323219b169269'},
      { href: "/products/", label: "Sodas", id: '64a7fdf613b323219b16926a' },
      { href: "/products/", label: "Pack de 12 à composer", id: '64a7fe0c13b323219b16926b' },
    ],
  },
  {
    id: "3",
    title: "Légal / Contact",
    menus: [
      { href: "/cgv", label: "CGV" },
      { href: "/mentions-legales", label: "Mentions légales" },
      { href: "/contact", label: "Nous contacter" },
    ],
  },
];

const Footer: React.FC = () => {
  const history = useHistory();

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {

    
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="">
          {menu.menus.map((item, index) => (
            <li key={index} className="xs:mt-2 lg:mt-1">
              <a
                key={index}
                className={`font-[400] dark:text-neutral-300 ${item?.label === "commercial@bieresdere-boutique.fr" && "underline"}`}
                onClick={() => { menu?.id === "2" && history.push({ pathname: item?.href, search: `?categories=${item?.label}` } )}}
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>

      <div className={`nc-Footer relative py-10 lg:pb-24`} style={{ backgroundColor: COLORS.footer, color: COLORS?.footerText }}>


        <div className="container flex lg:flex-row xs:flex-col justify-between">
          {widgetMenus.map(renderWidgetMenuItem)}

          <div className="">
            <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
              Suivez-nous
            </h2>
            <div className="col-span-2 flex items-center md:col-span-3">
              <SocialsList1 className="flex items-center lg:flex-col lg:space-y-3 xs:space-x-1 lg:items-start" />
            </div>
          </div>
        </div>

        <div className="container mt-[35px]">

          <div className="border-t border-[#BFC6DB] ">
            <div className="mt-[25px] flex grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 grid-cols-1 justify-between">

              <div>
                <h1 className="text-[13px] mt-[3px]">
                  Services E-commerce développé par <a target="_blank" className="underline hover:text-[#f90]" href="https://milango.io/">Milango</a>
                </h1>
              </div>

              <div className="flex flex-row lg:justify-end md:justify-end xl:justify-end">
                <h1 className="text-[13px] mt-[4px]">
                  Paiement sécurisé :
                </h1>

                <img width={60} className="ml-[8px]" src={stripeImg} />
                <img width={45} className="ml-[4px]" src={mastercardImg} />
                <img width={45} className="ml-[5px]" src={visaImg} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
