import { FC } from "react";
import HIW1 from "images/icon_1_hiw.svg";
import HIW2 from "images/icon_2_hiw.svg";
import HIW3 from "images/icon_3_hiw.svg";
import HIW4 from "images/icon_4_hiw.svg";
import { COLORS } from "config/theme";

export interface SectionHowItWorkProps {
  className?: string;
  data?: typeof DEMO_DATA[0][];
}

const themeColor = '#208bc9'

const DEMO_DATA = [
  {
    id: 1,
    icon: HIW1,
    title: "Bières artisanales de l'Île de Ré",
  },
  {
    id: 2,
    icon: HIW2,
    title: "Paiement sécurisé",
  },
  {
    id: 3,
    icon: HIW3,
    title: "Livraisons rapide partout en France",
  },
  {
    id: 4,
    icon: HIW4,
    title: "Service client basé en France",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork ${className}`}
      data-nc-id="SectionHowItWork"
      style={{ backgroundColor: COLORS?.backgroundHowItWork }}
    >

      <div className="p-4 container flex grid lg:grid-cols-4 xs:grid-cols-2 md:grid-cols-4 sm:grid-cols-2g gap-6 lg:gap-0 xl:gap-0 md:gap-0 items-center justify-center">
        {data.map((item: typeof DEMO_DATA[number], index: number) => (
          <div key={index} className="flex justify-center items-center">
            <div className="mr-4">
              <img className="hitImg" src={item?.icon} />
            </div>
            <div className={`max-w-[50%] text-[${themeColor}] font-[600]`}>
              {item?.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
