import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from "leaflet";
import moment from "moment";
import "leaflet/dist/leaflet.css";
import Label from "./Label/Label";
import AutoComplete from "react-google-autocomplete";
import { GOOGLE_MAP_API, API_ENDPOINT } from "../config/key";
import { getAllShippingAddress } from "actions/user";
import Button from "shared/Button/Button";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const GLSParcelShopsMap = ({ onMarkerClick, address, onChooseGLSRelayPoint }) => {
  const [parcelShops, setParcelShops] = useState([]);
  const [parcelShopSelect, setParcelShopSelect] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(address)
  const selectedMarkerRef = useRef(null);
  const mapRef = useRef(null);
  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(0);

  L.Icon.Default.imagePath = "/";

  const daysOfWeekFr = {
    "MON": "Lundi",
    "TUE": "Mardi",
    "WED": "Mercredi",
    "THU": "Jeudi",
    "FRI": "Vendredi",
    "SAT": "Samedi",
    "SUN": "Dimanche"
  };

  const handleChooseGLSRelayPoint = () => {
    if (parcelShopSelect) {
      onChooseGLSRelayPoint(parcelShopSelect.ParcelShopID);
    }
  };

  const handleMarkerClick = (shop) => {
    setParcelShopSelect(shop);
    onMarkerClick(shop.ParcelShopID, shop.Address);
    selectedMarkerRef.current = shop.ParcelShopID;
  };

  const fetchData = async () => {
    const data = await getAllShippingAddress();

    data?.shippingAddress.map(async () => {
      const response = await fetch(
        `${API_ENDPOINT}gls/distance`,
        {
          method: 'POST',
          body: JSON.stringify({
            "lat": shippingAddress?.lat,
            "long": shippingAddress?.long,
            "distance": "2"
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      const test = await response.json();
      setParcelShops(test?.ParcelShop);
    });
  };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    fetchData();
  }, [shippingAddress]);

  useEffect(() => {
    if (parcelShops.length > 0) {
      handleMarkerClick(parcelShops[defaultSelectedIndex])
    }
  }, [parcelShops]);

  useEffect(() => {
    console.log("selectedMarkerRef.current:", selectedMarkerRef.current);
    if (selectedMarkerRef?.current) {
      const marker = selectedMarkerRef?.current;
      if (marker && marker.openPopup) {
        marker.openPopup();
        console.log("Popup opened for marker:", marker);
      }
    }
  }, [selectedMarkerRef?.current]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView([shippingAddress?.lat, shippingAddress?.long], 13);
    }
  }, [shippingAddress]);

  return (
    <div id="map" className="w-full h-full border-[1px] rounded p-2 border-gray-200">
      <div className="mb-6">
        <Label>Adresse</Label>

        <AutoComplete
          apiKey={GOOGLE_MAP_API}
          onPlaceSelected={(place) => {
            setShippingAddress({
              address: place?.address_components[0]?.long_name + " " + place?.address_components[1]?.long_name,
              city: place?.address_components[2]?.long_name,
              country: place?.address_components[5]?.long_name,
              postalCode: place?.address_components[6]?.long_name,
              lat: place?.geometry?.location?.lat(),
              long: place?.geometry?.location?.lng()
            });
          }}
          options={{
            types: ["geocode", "establishment"],
          }}
          placeholder="Saisissez une adresse"
          defaultValue={shippingAddress?.address + ', ' + shippingAddress?.city + ', ' + shippingAddress?.country || ''}
          className='block w-full border-[1px] border-neutral-200  focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5'
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <div>
              <ul className="mt-3 px-2">
                {parcelShops?.map((shop, key) => (
                  <li onClick={() => handleMarkerClick(shop)} className={`relative px-4 cursor-pointer mb-2 hover:bg-gray-200 ${shop?.ParcelShopID === parcelShopSelect?.ParcelShopID && 'bg-gray-200'}`} key={key}>
                    {shop?.ParcelShopID === parcelShopSelect?.ParcelShopID &&
                      <div className="absolute z-[999] top-[-8px] right-[-6px] w-[20px] h-[20px] bg-[#208bc9] rounded-full flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 70.761 49.778">
                          <path fill="white" id="Check-Icon-09iujhd" d="M115.739,61.78,78.282,99.237a5.655,5.655,0,0,1-8.005,0L49.3,78.257a5.653,5.653,0,0,1,7.994-7.994L74.279,87.24l33.455-33.466a5.661,5.661,0,0,1,8.005,8.005" transform="translate(-47.133 -51.62)" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
                        </svg>
                      </div>
                    }
                    <h1 className="font-semibold text-[14px]">{shop?.Address?.Name1}</h1>
                    <h1 className="text-[13px]">{shop.Address.Street}</h1>
                    <h1 className="mb-2 text-[13px]">{shop.Address.ZIPCode} - {shop.Address.City}</h1>
                    {shop?.ParcelShopID === parcelShopSelect?.ParcelShopID &&
                      <button className="bg-[#208bc9] px-2 py-[.5] mb-2" onClick={handleChooseGLSRelayPoint}>
                        <span className="text-[white] text-[12px]">Choisir ce Point Relais®</span>
                      </button>
                    }
                    <hr />
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
        <div className="col-span-2 mt-3">
          <MapContainer
            ref={mapRef}
            center={[shippingAddress?.lat, shippingAddress?.long]}
            zoom={13}
            scrollWheelZoom={true}
            id="mapGLS" // This id should be unique on the page
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {parcelShops?.map((shop) => (
              <Marker
              key={shop.ParcelShopID}
              eventHandlers={{
                click: () => handleMarkerClick(shop)
              }}
              position={[Number(shop.Location.Latitude), Number(shop.Location.Longitude)]}
              ref={selectedMarkerRef.current === shop.ParcelShopID ? selectedMarkerRef : null}
              >
                <Popup className="m-0">
                  <h1 className="mb-2">{shop.Address.Name1}</h1>
                  <h1>{shop.Address.Street}</h1>
                  <h1 className="mb-2">{shop.Address.City} {shop.Address.ZIPCode}</h1>

                  <h1 className="mb-1">Horaires :</h1>
                  <ul>
                    {Object.keys(shop.WorkingDay)?.map((key) => (
                      <li key={key}>
                        {daysOfWeekFr[shop.WorkingDay[key].DayOfWeek]}: {moment(shop.WorkingDay[key]?.OpeningHours?.OpeningHours[0]?.From).format("HH:mm")} - {moment(shop.WorkingDay[key]?.OpeningHours?.OpeningHours[0]?.To).format("HH:mm")}
                      </li>
                    ))}
                  </ul>
                </Popup>
              </Marker>
            ))}

          </MapContainer>
        </div>
      </div>

    </div>
  );
};

export default GLSParcelShopsMap;
