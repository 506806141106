import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import Avatar from "../../images/avatar.png"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory } from "react-router-dom";

const PageHome = () => {
  const history = useHistory();

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Bières de Ré</title>
        <meta name="description" content="Toutes les bières naturelles brassées à l' île de Ré à Sainte Marie de Ré par la brasserie artisanale Bières de Ré. Bières blanche, blonde, cognac, agrumes, ..." />  
      </Helmet>

      {/* CAROUSSEL  */}
      <SectionHero />
      {/* CAROUSSEL  */}


      {/* SECTION HOW IT WORK */}
      <SectionHowItWork />
      {/* SECTION HOW IT WORK */}


      {/* INTRODUCTION */}
      <div className="cover-background py-24 relative">
        <div className="flex justify-center items-center h-full">
          <div className="bg-white p-10 flex justify-center items-center">
            <div className="max-w-[1000px] mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 xl:grid-cols-3 gap-4">
                <div className="flex items-center justify-center">
                  <img src={Avatar} />
                </div>
                <div className="col-span-2 w-full text-center lg:text-left md:text-left xl:text-left">
                  <h1 className="text-[#603914] text-[40px] font-[600] leading-[45px] ">BRASSERIE ARTISANALE</h1>
                  <h2 className="text-[#7c7d7d] text-[32px] mt-8">DEPUIS 1996 SUR L'ÎLE DE RÉ</h2>

                  <div className="text-[14px] text-[#555]">

                    <p className="mt-8">Nos bières artisanales, très appréciées par les amateurs, offrent des arômes délicats et savoureux, une mousse fine et légère et le charme de l'océan.
                      Les boissons BIO ORÉA (Cola, Limonade ou Tonic), fraîches et désaltérantes vous dévoileront leurs arômes naturels et gourmands, qui vous feront voyager dans vos souvenirs d'enfance.</p>

                    <p className="mt-2"> Vos apéritifs et cocktails ne seront pas en reste : laissez-vous tenter par la gamme de spiritueux LE PERTUIS.</p>

                    <p className="mt-2">En ligne ou en boutique, toute l'équipe sera ravie de vous accueillir afin de vous orienter dans le choix des produits.</p>
                  </div>

                  <ButtonPrimary onClick={() => history.push("/contact")} className="mt-8">Contactez-nous</ButtonPrimary>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* INTRODUCTION */}


    </div>
  );
}

export default PageHome;
