import { getAllCategories, getAllCategoriesFlat } from "actions/categories";
import { getAllFilter, getAllProductsFilter } from "actions/products";
import ProductCard from "components/ProductCard";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-use";
import { useHistory } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import FilterMobile from "shared/Navigation/FilterMobile";

const ProductCategory = () => {
    let location: any = useLocation();
    const history = useHistory();
    const [productCat, setProductCat] = useState([]);
    const [refreshCounter, setRefreshCounter]: any = useState(0);
    const [categories, setCategories] = useState([]);
    const [categoriesFlat, setCategoriesFlat] = useState([]);
    const [arrCollapseCat, setArrCollapseCat]: any = useState([]);
    const [filter, setFilter]: any = useState()
    const [isOpen, setIsOpen] = useState(true);
    const [allFilters, setAllFilters]: any = useState([]);

    const [loading, setLoading] = useState(true);
    const [isVisable, setIsVisable] = useState(false);


    // PAGINATION
    let itemsPerPage = 9;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = productCat?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(productCat?.length / itemsPerPage);
    // PAGINATION 

    const [visibleProducts, setVisibleProducts] = useState(itemsPerPage);
    const [loadingMore, setLoadingMore] = useState(false);

    const handlePagePaginateClick = (event: any) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const newOffset = (event.selected * itemsPerPage) % productCat?.length;

        setItemOffset(newOffset);
    };


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const categoryParams = queryParams.get("categories");

        console.log(categoryParams)

        if (categoryParams) {
            const selectedCategories = categoryParams.split(";"); // Assuming categories are separated by semicolons
            setFilter({ ...filter, categories: selectedCategories });
        } else {
            setFilter({ ...filter, categories: [] });
        }
    }, [location.search]);

    useEffect(() => {
        getAllCategories().then((res) => {
            setCategories(res)
        })
        getAllCategoriesFlat().then((res) => {
            setCategoriesFlat(res)
        })
    }, [])

    useEffect(() => {

        const categoryNames = filter?.categories; // Tableau de noms de catégories

        // Créer un tableau d'ID de catégories à filtrer
        getAllCategoriesFlat().then((resFlat) => {

            const categoryIdsToFilter = categoryNames?.map((categoryName: any) => {
                const category: any = resFlat.find((cat: any) => cat.name === categoryName);
                return category ? category._id : null;
            }).filter((categoryId: any) => categoryId !== null); // Filtre les ID null (catégories non trouvées)

            if (categoryIdsToFilter) {
                getAllProductsFilter({ categories: categoryIdsToFilter }).then((res) => {
                    setProductCat(res?.result);
                    setLoading(false); // Fin du chargement

                    const arrProductId: any = [];

                    res?.result?.map((it: any) => {
                        arrProductId.push(it?._id)
                    })

                    getAllFilter({ "productId": arrProductId }).then((res) => {
                        setAllFilters(res)
                    })
                })
            }
            setCategoriesFlat(resFlat)
        })
    }, [filter]);

    const handleChangeCategorie = (name: string) => {
        const category = categoriesFlat.find((cat: any) => cat.name === name);

        if (!category) {
            return; // Category not found, handle this case as needed
        }

        const newCategories = filter.categories.includes(name)
            ? filter.categories.filter((catName: string) => catName !== name)
            : [...filter.categories, name];

        setFilter({ ...filter, categories: newCategories });

        // Update URL here
        updateURL({ categories: newCategories });
    };

    const handleChooseFilter = (item: any, it: any) => {
        const newItem = Array.isArray(filter[item]) && filter[item]?.includes(it)
            ? filter[item].filter((catId: any) => catId !== it)
            : [...filter[item], it];

        setFilter({ ...filter, [item]: newItem });

        // Update URL here
        updateURL({ [item]: newItem });
    };

    const updateURL = (newFilters: any) => {
        const queryParams = new URLSearchParams();

        Object.keys(newFilters).forEach((key) => {
            if (Array.isArray(newFilters[key])) {
                const values = newFilters[key].join(';'); // Join values with semicolons
                queryParams.append(key, values);
            }
        });

        history.push({
            pathname: '/products',
            search: queryParams.toString(),
        });
    };

    const renderCategories = (item: any) => Object.keys(item).map((it, index) => {
        const hasSubCat = Array.isArray(item[it]?.subCat) && item[it]?.subCat.length > 0;

        const toggleOpen = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div key={index}>
                <ul>
                    <li className="flex items-center mb-2">
                        {item[it]?.name !== "Boutique" &&
                            <>
                                {hasSubCat && (
                                    <svg
                                        onClick={() => toggleOpen()}
                                        className={`w-[22px] relative top-[.1rem] arrow-icon cursor-pointer ${arrCollapseCat.indexOf(item[it]._id) !== -1 && "arrow-sub-menu"
                                            }`}
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                )}

                                <input
                                    type="checkbox"
                                    name={item[it].name}
                                    id={item[it].name}
                                    checked={filter.categories.includes(item[it]?.name)}
                                    onChange={() => handleChangeCategorie(item[it]?.name)}
                                    className="focus:ring-action-primary p-2 text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 mt-1"
                                />

                                <label htmlFor={item[it].name} className="ml-2 text-sm">
                                    {item[it]?.name}
                                </label>
                            </>
                        }
                    </li>

                    {hasSubCat && isOpen && (
                        <li className="ml-3">{renderCategories(Object.assign({}, item[it]?.subCat))}</li>
                    )}
                </ul>
            </div>
        );
    });

    const handleOpenMenu = () => setIsVisable(true);
    const handleCloseMenu = () => setIsVisable(false);

    const renderFilterMenu = () => {
        return (
            <Transition appear show={isVisable} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={handleCloseMenu}
                >
                    <div className="fixed right-0 top-0 bottom-0 w-full w-full md:w-auto z-index outline-none focus:outline-none">
                        <React.Fragment>
                            <Transition.Child
                                as={Fragment}
                                enter="transition duration-300 transform"
                                enterFrom="opacity-0 translate-x-14"
                                enterTo="opacity-100 translate-x-0"
                                leave="transition duration-200 transform"
                                leaveFrom="opacity-100 translate-x-0"
                                leaveTo="opacity-0 translate-x-14"
                            >
                                <div className="z-20 relative">
                                    <FilterMobile onClickClose={handleCloseMenu} />
                                </div>
                            </Transition.Child>

                            <Transition.Child
                                as={Fragment}
                                enter="duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-neutral-900/60" />
                            </Transition.Child>
                        </React.Fragment>
                    </div>
                </Dialog>
            </Transition>

        );
    };

    const handleRemoveFilterCategories = () => {
        setFilter({ categories: [] })
        const currentURL = window.location.href;
        const baseURL = currentURL.split('/products')[0] + '/products';
        window.location.href = baseURL;
    }

    const handleLoadMore = () => {
        if (!loadingMore) {
            setLoadingMore(true);

            setTimeout(() => {
                if (visibleProducts + itemsPerPage <= productCat.length) {
                    setVisibleProducts(prev => prev + itemsPerPage);
                } else {
                    setVisibleProducts(productCat.length);
                }
                setLoadingMore(false);
            }, 600);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const triggerOffset = window.innerHeight * 0.75; // Modifiez ce pourcentage selon vos besoins
    
            const isNearBottom = document.documentElement.scrollHeight -  (window.innerHeight + window.scrollY) <= triggerOffset;
    
            if (
                visibleProducts &&
                productCat &&
                productCat.length > 9 &&
                visibleProducts !== productCat.length
            ) {
                if (isNearBottom && !loadingMore) {
                    handleLoadMore();
                }
            }
        };
    
        // Ajoute un gestionnaire d'événement de défilement
        window.addEventListener('scroll', handleScroll);
    
        // Retire le gestionnaire d'événement de défilement lors du démontage du composant
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loadingMore, productCat, visibleProducts]);


    return (
        <div className="container py-16 lg:pb-28 lg:pt-18 space-y-16 sm:space-y-20 lg:space-y-28">
            <div>

                <div className="max-w-screen-sm mb-14 flex flex-col text-center">
                    <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
                        Produits
                    </h2>
                </div>


                <div className="flex justify-between">
                    <div className="flex flex-row">
                        <div>

                            <span className="hidden md:block lg:block xl:block font-[500]">Filtres</span>

                            <ButtonSecondary onClick={handleOpenMenu} className="block lg:hidden md:hidden xl:hidden font-[500]">Filtres</ButtonSecondary>

                            <span onClick={() => setFilter({ categories: [] })} className="hidden md:block lg:block xl:block ml-20 top-0 relative md:top-[-24px] lg:top-[-24px] xl:top-[-24px] cursor-pointer hover:underline text-[#208bc9] font-[500]">Réinitialiser</span>
                        </div>

                        <div className="h-5 border-l border-slate-300 mt-3 md:mt-0 lg:mt-0 xl:mt-0 dark:border-slate-700 mr-6 ml-6"></div>
                        <div className=" mt-3 md:mt-0 lg:mt-0 xl:mt-0 ">
                            <span className="font-[500]">{productCat.length} produits</span>
                        </div>
                    </div>
                    {/* <div>
                        {renderTabsSortOrder()}
                    </div> */}
                </div>

                <hr className="border-slate-200 dark:border-slate-700 mt-5" />

                {filter?.categories?.length !== 0 && (
                    <div className="mt-6">
                        <span className="border-[.15rem] p-2 rounded-3xl text-xs relative">
                            {filter?.categories?.length} {`filtre${filter?.categories?.length > 1 ? 's' : ''}`} catégorie{filter?.categories?.length > 1 ? 's' : ''}

                            <div onClick={() => handleRemoveFilterCategories()} className="cursor-pointer top-[-10px] w-[20px] h-[20px] absolute right-[-8px] bg-[#208bc9] rounded-full flex justify-center items-center">
                                <p className="text-[white]">x</p>
                            </div>
                        </span>

                    </div>
                )}


                <main>
                    <div className="flex flex-col lg:flex-row">
                        <div className="lg:w-1/3 xl:w-1/4 pr-4 ">
                            <div className="relative flex flex-col pb-6 border-[1px] border-[transparent] hidden md:block lg:block xl:block border-b-[#e2e8f0] pt-8">

                                {/* FILTERS */}
                                <h3 className="font-semibold mb-6">Catégories</h3>

                                <div className="mb-2">
                                    <input
                                        type={'checkbox'}
                                        name={'all'}
                                        id={'all'}
                                        checked={filter?.categories.length === 0}
                                        onChange={() => setFilter({ categories: [] })}
                                        className={`focus:ring-action-primary p-2 text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500`}
                                    />

                                    <label htmlFor="all" className="ml-2 text-[15px] relative top-[2px]">Tout</label>
                                </div>

                                {renderCategories(categories)}
                            </div>

                            {/* <div className="border-[1px] border-[transparent] border-b-[#e2e8f0]"> */}
                            {/* {renderTabsPriceRage()} */}
                            {/* </div> */}


                            <>
                                {Object.keys(allFilters).map((item: any, index: number) => {
                                    const uniqueFilters = allFilters[item].filter(
                                        (it: any, i: number, arr: any[]) => arr.indexOf(it) === i
                                    );

                                    return (
                                        <div key={index} className="relative flex flex-col pb-6 pt-8">
                                            {/* FILTERS */}
                                            <h3 className="font-semibold mb-6 capitalize">{item}</h3>

                                            {uniqueFilters.map((it: any, i: number) => (
                                                <div key={i} className="mb-2">
                                                    <input
                                                        type={'checkbox'}
                                                        name={it}
                                                        id={it}
                                                        checked={filter[item]?.includes(it)}
                                                        onChange={() => handleChooseFilter(item, it)}
                                                        className={`focus:ring-action-primary p-2 text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500`}
                                                    />

                                                    <label
                                                        htmlFor="all"
                                                        className="ml-2 text-[15px] relative top-[2px]"
                                                    >
                                                        {it.charAt(0).toUpperCase() + it.slice(1)}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </>



                        </div>

                        <div className="flex-shrink-0 lg:mb-0 lg:mx-4 lg:border-t-0"></div>

                        <div className="flex-1">

                            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-8">
                                {loading ? (
                                    <>
                                        {[1, 2, 3, 4, 5, 6].map((_, index) => (
                                            <div
                                                key={index}
                                                role="status"
                                                className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
                                            >
                                                <div role="status" className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
                                                    <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                                                        <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                                            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                                                            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                                                        </svg>
                                                    </div>
                                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                                    <span className="sr-only">Loading...</span>
                                                </div>


                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    Object.keys(productCat).slice(0, visibleProducts).map((key: any, index) => (
                                        <ProductCard
                                            refreshFnc={() => setRefreshCounter(refreshCounter + 1)}
                                            data={productCat[key]}
                                            key={index}
                                        />
                                    ))
                                )}
                            </div>

                            {loadingMore &&
                                <div className="flex justify-center">
                                    <svg className="w-12 h-12 animate-spin text-[#208bc9]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 4.75V6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M19.25 12L17.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M12 17.75V19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M6.25 12L4.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </div>
                            }

                            {(productCat?.length === 0 && !loading) &&
                                <div className="absolute left-1/2 mt-0 md:mt-14 lg:mt-14 xl:mt-14 transform -translate-x-1/2 whitespace-nowrap">Aucun produit dans cette catégorie</div>
                            }
                        </div>


                    </div>

                </main>

                {renderFilterMenu()}

            </div>
        </div>
    );
};

export default ProductCategory;
