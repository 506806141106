import React, { useEffect, useState } from 'react';
import { getAnalytics } from 'actions/integrations';

const AnalyticsScript = () => {
    const [analyticsScript, setAnalyticsScript] = useState();

    const loadAnalyticsScript = () => {
        // Check if axeptioScript is not empty before appending to the DOM
        if (analyticsScript) {
            const scriptElement = document.createElement('script');
            scriptElement.innerHTML = analyticsScript;
            document.head.appendChild(scriptElement);
        }
    };

    useEffect(() => {
        getAnalytics()
            .then((res) => {
                setAnalyticsScript(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        loadAnalyticsScript();
    }, [analyticsScript]);

    return null;
};

export default AnalyticsScript;
