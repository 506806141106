import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import MyRouter from "routers/index";
import { checkTokenValidity } from "actions/token";
import { useDispatch } from "react-redux";
import { getAllProductsInWishlist } from "actions/wishlist";
import { getUserInfos } from "actions/user";
import { getProductInCart } from "actions/cart";
import { GET_CART, GET_WISHLIST, LOGIN_SUCCESS, LOGOUT, USER_INFO_SUCCESS } from "actions/types";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import "./services/i18n";
import { setLanguage } from "actions/language";
import AxeptioScript from "components/Integrations/AxeptioScript";
import HotjarScript from "components/Integrations/HotjarScript";
import AnalyticsScript from "components/Integrations/AnalysticsScript";

const token = localStorage.getItem('token');;

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {

    // DEFAULT LANGUAGE INTO REDUX STORE
    dispatch(setLanguage('fr'));

    const result = async () => {

      if (token !== null) {
        checkTokenValidity(token).then(async (res: any) => {
          if (res.data === true) {
            await dispatch(getAllProductsInWishlist());
            await dispatch(getUserInfos());
            await dispatch(getProductInCart());
          } else {
            dispatch({ type: LOGOUT });

            localStorage?.clear();

            dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                token: null,
                user: null
              },
            });

            dispatch({
              type: USER_INFO_SUCCESS,
              payload: null
            });

            dispatch({
              type: GET_CART, payload: null
            });

            dispatch({
              type: GET_WISHLIST, payload: null
            });

          }
        })
      }

    };

    result();
  })

  return (
    <>
      <div>
        <AnalyticsScript />
        <HotjarScript />
        <AxeptioScript />
        <Toaster />
      </div>
      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>

      <NotificationContainer />
    </>
  );
}

export default App;
