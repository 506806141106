export const COLORS = {


    preHeader: "#F7ECDB",
    preHeaderText: "#234336",

    // NAVBAR 
    navbar: '#208bc9',
    navbarText: '#FFFFFF',
    navbarTextHover: '#FFFFF',
    navbarArrow: '#FFFFFF',


    // SLIDER
    sliderText: 'red',
    sliderBg: 'white',
    sliderBgText: "black",

    // FOOTER
    footer: '#cfedff',
    footerText: '#121212',


    // BUTTON
    buttonPrimary: '#208bc9',
    buttonSecondary: '#208bc9',
    buttonThird: 'white',
    filterBtn: '#6E5D4A',

    // INPUT
    searchInputBorder: 'white',

    heading: '#234336',
    text: '#234336',
    headingCaroussel: "#208bc9",
    subHeadingCaroussel: "black",
    paragraphCaroussel: "#234336",
    placeholderSearchInput: 'white',
    backgroundHowItWork: '#cfedff',
    loadingSpinner: '',
}

export const BORDERS = {
    buttonVariant: '5px',
    input: '14px',
    buttonPrimary: '3px',
    buttonSecondary: '3px',
    buttonThird: '3px',
    searchInput: '999px',
    inputQuantityNumber: '0px',
    input: ''
}
