
import { Helmet } from "react-helmet";
import n1 from "../../images/n1.png";
import n2 from "../../images/n2.png";

import l1 from "../../images/l1.png";
import l2 from "../../images/l2.png";
import l3 from "../../images/l3.png";
import { Link } from "react-router-dom";

const NosEngagements = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Nos engagements - Bières de Ré</title>
            </Helmet>

            <div className="bg-[#cfedff] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16">
                    <Link to="/" className="underline cursor-pointer font-[500]">Accueil</Link>
                    <span className="ml-2 mr-2">{">"}</span>
                    <h1>Nos engagements</h1>
                </div>
            </div>

            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-8">

                            <div>
                                <h1 className="text-[#725E39] font-semibold text-[40px] leading-[45px]">Engagement local et durable</h1>
                            </div>

                            <div className="mt-10">
                                <h1 className="text-[32px] text-[#7c7d7d] leading-[45px]">Une production entre tradition et modernité</h1>
                            </div>



                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 md:lg:grid-cols-2 xl:grid-cols-2 mt-10">
                                <div>
                                    <img className="w-full" src={n1} />
                                </div>

                                <div className="text-[14px] leading-[23px]">
                                    <p>Au fil du temps, la gamme s'est élargie et la brasserie propose trois bières traditionnelles (blanche, blonde et ambrée) et leurs déclinaisons en version bio. S'ajoutent des créations audacieuses comme la bière au miel de Ré, la Triple et ses trois houblons, la Déferlante et sa saveur très marquée, l'Indian Pale Ale ou encore les bières aromatisées au Cognac ou aux agrumes. Des bières artisanales d'exception, médaillées lors de prestigieux concours.
                                        Bières de Ré se développe et n’a cessé même si, depuis la reprise de la famille Bertrand, la volonté est d'innover et de moderniser la brasserie, il n'a pas été perdu l'envie de garder un savoir-faire authentique. La brasserie va ainsi privilégie la qualité des saveurs et des matières premières en mettant en valeur le circuit court. Il va notamment sélectionner des matières premières prioritairement régionales ou françaises. Enfin ils distribuent aussi les produits sur l'île et dans le pays Rochelais et maintenant en livraison directement chez vous.
                                        L'ingrédient principal étant le malt et le houblon, ils sont récoltés en France et les bières sont sans conservateur, ni additif, ni colorant. Si vous trouvez un léger trouble de la bière, il provient de la deuxième fermentation en bouteille, dans le respect de la fabrication des bières artisanales.</p>
                                    <p className="mt-3">Toujours aussi curieux et grâce à leurs fortes expériences, Mathieu et Ionut continuent à innover dans de nouvelles recettes mais toujours de grandes qualités aux arômes subtils et délicats.</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 md:lg:grid-cols-2 xl:grid-cols-2 mt-10">
                                <div className="text-[14px] leading-[22px] flex items-center order-2 lg:order-1 xl:order-1 md:order-1">
                                    <p>Avec leurs volontés d'innover dans la création et production de leurs bières, nos brasseurs ont a coeur les enjeux écologiques actuels. La brasserie Bières de Ré ne cesse de diminuer son impact environnemental par des actions sur sa production comme réduire sa consommation d’énergie et d’eau par un système de récupération.<br />
                                        Certains « déchets » de la production de bière, c'est à dire les drêches, sont recyclées dans l'alimentation animale avec comme fil conducteur, le fort ancrage local inscrit au coeur de son ADN.<br />
                                        Utiliser des matières premières de qualité, régionales ou françaises de préférence, sont la clé de ce succès. L'essentiel des ventes est réalisé sur l'île et dans le Pays Rochelais.<br />
                                        Aujourd’hui, toute l’équipe de Bières de Ré est ravie de vous accueillir dans cet espace de convivialité et d'échange. Depuis notre boutique, vous pouvez apercevoir la salle de brassage. Des visitées guidées sont organisées, sur rendez-vous, afin de découvrir les lieux, les processus de fabrication, ainsi que les différentes machines utilisées.</p>
                                </div>
                                <div className="order-1 lg:order-2 xl:order-2 md:order-2">
                                    <img className="w-full" src={n2} />
                                </div>
                            </div>

                            <div className="mt-14">
                                <h1 className="text-[32px] text-[#7c7d7d]">Bière associative</h1>
                            </div>

                            <div className="text-[14px] leading-[22px] flex items-center mt-6">
                                <p>En plus d'être sensible aux enjeux environnementaux, ils souhaitent être acteur local en soutenant des associations. Chaque année, à travers notre bière associative « Les Ânes du Pertuis », nous soutenons une association locale.
                                    Protection du patrimoine, culturel ou environnemental de l'Île de Ré, tout projet qui met en avant les valeurs locales peut être soutenu par notre bière associative. Et chaque année nous choisissons une association. Cela nous permet d'être acteur économique mais surtout de participer à la préservation de cette île dont nous sommes tombés amoureux. À chaque bière vendue, 0,40€ sont donc reversés à une association. L'étiquette de la bière "Les Ânes du Pertuis", représente 3 ânes, symbole de l'île et du rôle important que cet animal a dans la vie rhétaise. Il fait parti intégrante de l'histoire de l'île de Ré, en modelant ses paysages et en contribuant à leur préservation.</p>
                            </div>

                            <div className="grid grid-cols-3 justify-between w-full mt-10 place-items-center">
                                <div>
                                    <img src={l1} />
                                </div>
                                <div>
                                    <img src={l2} />
                                </div>
                                <div>
                                    <img src={l3} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NosEngagements;
