import { FC, useState } from "react";

import img1 from "images/188.jpeg";
import img2 from "images/189.jpeg";
import img3 from "images/190.jpeg";
import img4 from "images/192.jpeg";

import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import { COLORS } from "config/theme";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface HeroDataType {
    image: string;
    heading: string;
    subHeading: string;
    btnLink: string;
}
export interface SectionHeroProps {
    className?: string;
}

const DATA: HeroDataType[] = [
    {
        image: img1,
        heading: "",
        subHeading: '"retour de la plage" le 15 aout de 10h à 18h',
        btnLink: "/",
    },
    {
        image: img2,
        heading: "",
        subHeading: '',
        btnLink: "/",
    },
    {
        image: img3,
        heading: "",
        subHeading: "",
        btnLink: "/",
    },
    {
        image: img4,
        heading: "",
        subHeading: "",
        btnLink: "/",
    }
];

let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
    // =================
    const [indexActive, setIndexActive] = useState(0);
    const [isRunning, toggleIsRunning] = useBoolean(true);

    useInterval(
        () => {
            handleAutoNext();
        },
        isRunning ? 5500 : null
    );


    const handleAutoNext = () => {
        setIndexActive((state) => {
            if (state >= DATA.length - 1) {
                return 0;
            }
            return state + 1;
        });
    };

    const handleClickNext = () => {
        setIndexActive((state) => {
            if (state >= DATA.length - 1) {
                return 0;
            }
            return state + 1;
        });
        handleAfterClick();
    };

    const handleClickPrev = () => {
        setIndexActive((state) => {
            if (state === 0) {
                return DATA.length - 1;
            }
            return state - 1;
        });
        handleAfterClick();
    };

    const handleAfterClick = () => {
        toggleIsRunning(false);
        if (TIME_OUT) {
            clearTimeout(TIME_OUT);
        }
        TIME_OUT = setTimeout(() => {
            toggleIsRunning(true);
        }, 1000);
    };

    const renderItem = (index: number) => {
        const isActive = indexActive === index;

        const item = DATA[index];
        if (!isActive) {
            return null;
        }

        return (
            <div
                className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
                key={index}
            >

                {/* BG */}
                <div className="absolute inset-0" style={{ backgroundColor: COLORS?.sliderBg }}>

                </div>

                <div className="sm:mb-10">
                    <div className="w-full relative">
                        <img
                            className="w-full h-[600px] xs:h-[500px] object-cover nc-SectionHero2Item__image"
                            src={item.image}
                            alt={item.heading}
                        />

                        <Prev
                            className="absolute text-[white] lg:left-[10px] md:left-[10px] opacity-[.85] absolute top-1/2 xs:left-[37px] sm:left-14 xs:transform sm:transform xs:-translate-x-1/2 sm:-translate-x-1/2 -translate-y-1/2 z-[1]"
                            btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                            svgSize="w-10 h-10"
                            onClickPrev={handleClickPrev}
                        />
                        <Next
                            className="absolute text-[white] lg:right-[10px] md:right-[10px] opacity-[.85] absolute top-1/2 xs:right-[-10px] sm:right-1 xs:transform sm:transform xs:-translate-x-1/2 sm:-translate-x-1/2 -translate-y-1/2 z-[1]"
                            btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                            svgSize="w-10 h-10"
                            onClickNext={handleClickNext}
                        />

                        <div
                            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:w-full ${item?.heading === "" ? "hidden" : ""}`}
                            style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                        >
                            <div className="lg:px-24 lg:py-12 md:p-6 sm:p-3 xs:px-4 xs:py-10">
                                <h1
                                    style={{ color: COLORS?.headingCaroussel }}
                                    className="font-[500] uppercase lg:text-[35px] sm:mb-3 md:text-[23px] xs:text-[32px] sm:text-[26px] sm:leading-[30px] xs:leading-[50px] text-center lg:leading-[50px]"
                                >
                                    {item?.heading}
                                </h1>
                                <h2
                                    style={{ color: COLORS?.subHeadingCaroussel }}
                                    className="uppercase text-center mt-4"
                                >
                                    {item?.subHeading}
                                </h2>
                                
                                <div className="flex justify-center">
                                    <ButtonPrimary className="mt-8">En savoir plus</ButtonPrimary>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return <>{DATA.map((_, index) => renderItem(index))}</>;
};

export default SectionHero;
