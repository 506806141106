import { changePassword } from "actions/user";
import Label from "components/Label/Label";
import { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { NotificationManager } from 'react-notifications';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const AccountPass = () => {
  const [currPass, setCurrPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [rptNewPass, setRptNewPass] = useState('');

  const handleChangePassword = () => {
    if (newPass === rptNewPass && newPass !== '' && rptNewPass !== '') {
      changePassword(currPass, newPass).then((response: any) => {
        NotificationManager.success(response, '')
      }).catch((err: any) => {
        NotificationManager.error(err.response.data.error, '')
      })
    } else {
      if (newPass === '' && rptNewPass === '') {
        NotificationManager.error("Merci de bien vouloir remplir tout les champs !", '')
      } else if (newPass !== rptNewPass) {
        NotificationManager.error("Les nouveaux mots de passe ne sont pas identiques !", '')
      }
    }
  }

  return (
    <div>
      <Helmet>
        <title>Modifier mot de passe - Bières de Ré</title>
      </Helmet>
      <div className={"container py-14"}>
        <div className="flow-root">
          <div className="flex justify-center flex-col lg:px-28">
            <div className="flex flex-row gap-1 font-[500] text-[13px]">
              <a href="/" className="text-primary-6000 hover:underline">Accueil</a>
              <span>{">"}</span>
              <a href="">Modifier mot de passe</a>
            </div>
            <div className="mt-16 mb-16">
              <div className="flex w-full">
                <div className="text-2xl sm:text-3xl mb-8 font-semibold flex flex-row">
                  <Link to="/customer/overview" className="w-10 h-7 relative top-[.1rem] flex items-center justify-center rounded bg-slate-200 opacity-[.6] hover:opacity-[1] text-neutral-700 nc-shadow-lg ">
                    <span className="text-[20px] font-[500]">{"<"}</span>
                  </Link>
                  <h1 className="ml-4">Modifier mot de passe</h1>
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <Label>Mot de passe actuel</Label>
                  <Input type="password" onChange={(e) => setCurrPass(e.target.value)} placeholder="**********" className="mt-1.5" />
                </div>
                <div>
                  <Label>Nouveau mot de passe</Label>
                  <Input type="password" onChange={(e) => setNewPass(e.target.value)} placeholder="**********" className="mt-1.5" />
                </div>
                <div>
                  <Label>Confirmer mot de passe</Label>
                  <Input type="password" onChange={(e) => setRptNewPass(e.target.value)} placeholder="**********" className="mt-1.5" />
                </div>
                <div onClick={() => handleChangePassword()} className="pt-2">
                  <ButtonPrimary>Modifier</ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPass;
