
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { deleteProductInAnonymousCart, deleteProductInCart } from "actions/cart";
import Prices from "components/Prices";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { IMG_LINK } from "../../config/key";
import { COLORS } from "config/theme";
import { t } from "i18next";
import PlaceIcon from "shared/NcImage/PlaceIcon";


export default function CartDropdown() {
  const { cart }: any = useSelector((state: any) => state);
  const { auth }: any = useSelector((state: any) => state);
  const history = useHistory();

  const themeColor = "#6E5D4A";

  const dispatch = useDispatch();
  const cartAnonymous: any = useSelector(state => state);


  const handleDeleteProductInCart = async (itemId: string) => {
    if (auth?.token) {
      await dispatch(deleteProductInCart(itemId)).then((res: any) => {
      })
    } else {
      await dispatch(deleteProductInAnonymousCart(cartAnonymous?.cart?._id, itemId)).then((res: any) => {

      })
    }
  }

  const handleRedirectToCart = (close: VoidFunction) => {
    close();
    history.push({ pathname: "/cart" })
  }

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`fill-[white]
                ${open ? "" : "text-opacity-90"}
                 group w-10 h-10 sm:w-12 sm:h-12 hover:bg-[${COLORS?.navbarTextHover}] dark:hover:bg-slate-800 rounded-full inline-flex items-center justify-center focus:outline-none relative`}
          >
            <div className="w-3.5 h-3.5 flex items-center justify-center bg-[white] absolute top-1.5 right-1.5 rounded-full text-[10px] leading-none font-medium">
              <span className={`mt-[1px] text-[black]`}>{cart?.cartLength} {cart?.items?.cartLength} {Object.keys(cart).length === 0 && 0}</span>
            </div>

            <svg width="21" height="21" viewBox="0 0 21 21" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75V0.25ZM3.26866 1L3.99307 0.805774C3.90517 0.477941 3.60807 0.25 3.26866 0.25V1ZM6.95522 14.75L6.23081 14.9442C6.32788 15.3062 6.67731 15.541 7.04918 15.4941L6.95522 14.75ZM18.2985 13.3177L18.3925 14.0618C18.7111 14.0215 18.9688 13.7828 19.0333 13.4682L18.2985 13.3177ZM20 5.01041L20.7347 5.1609C20.78 4.94018 20.7235 4.71083 20.5811 4.53626C20.4387 4.36169 20.2253 4.26041 20 4.26041V5.01041ZM4.34391 5.01041L3.61949 5.20463L3.61949 5.20463L4.34391 5.01041ZM1 1.75H3.26866V0.25H1V1.75ZM7.04918 15.4941L18.3925 14.0618L18.2046 12.5736L6.86127 14.0059L7.04918 15.4941ZM19.0333 13.4682L20.7347 5.1609L19.2653 4.85992L17.5638 13.1672L19.0333 13.4682ZM2.54424 1.19423L3.61949 5.20463L5.06832 4.81618L3.99307 0.805774L2.54424 1.19423ZM3.61949 5.20463L6.23081 14.9442L7.67964 14.5557L5.06832 4.81618L3.61949 5.20463ZM20 4.26041H4.34391V5.76041H20V4.26041ZM9.75 18.5006C9.75 18.9216 9.4141 19.25 9.01563 19.25V20.75C10.2568 20.75 11.25 19.7357 11.25 18.5006H9.75ZM9.01563 19.25C8.61716 19.25 8.28125 18.9216 8.28125 18.5006H6.78125C6.78125 19.7357 7.7745 20.75 9.01563 20.75V19.25ZM8.28125 18.5006C8.28125 18.0795 8.61716 17.7511 9.01563 17.7511V16.2511C7.7745 16.2511 6.78125 17.2654 6.78125 18.5006H8.28125ZM9.01563 17.7511C9.4141 17.7511 9.75 18.0795 9.75 18.5006H11.25C11.25 17.2654 10.2568 16.2511 9.01563 16.2511V17.7511ZM17.6667 18.5006C17.6667 18.9216 17.3308 19.25 16.9323 19.25V20.75C18.1734 20.75 19.1667 19.7357 19.1667 18.5006H17.6667ZM16.9323 19.25C16.5338 19.25 16.1979 18.9216 16.1979 18.5006H14.6979C14.6979 19.7357 15.6912 20.75 16.9323 20.75V19.25ZM16.1979 18.5006C16.1979 18.0795 16.5338 17.7511 16.9323 17.7511V16.2511C15.6912 16.2511 14.6979 17.2654 14.6979 18.5006H16.1979ZM16.9323 17.7511C17.3308 17.7511 17.6667 18.0795 17.6667 18.5006H19.1667C19.1667 17.2654 18.1734 16.2511 16.9323 16.2511V17.7511Z" />
            </svg>

          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className={`md:block absolute z-10 w-screen max-w-lg sm:max-w-md xs:max-w-md ${auth?.user ? "xs:-right-14" : "xs:-right-6"} sm:-right-14 px-4 mt-3.5 -right-28 sm:right-0 sm:px-0`}>
              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 mt-2">
                <div className="relative bg-white dark:bg-neutral-800">
                  <div className="max-h-[60vh] p-5 overflow-y-auto hiddenScrollbar">
                    <h3 className="text-xl font-semibold">Panier</h3>
                    <div className="divide-y divide-slate-100 dark:divide-slate-700">

                      {cart?.item &&
                        <>
                          {Object.keys(cart?.item).map((itemId: any, index: number) => (

                            <div key={index} className="flex py-5 last:pb-0">
                              <div className="relative h-20 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">

                                <div key={index}>
                                  {cart?.item[itemId]?.itemRef?.images?.image1Id
                                    ?
                                    <>
                                      <img
                                        className="h-full w-full"
                                        src={IMG_LINK + cart?.item[itemId]?.itemRef?.images?.image1Id}
                                        alt={cart?.item[itemId]?.itemRef?.images?.image1Id}
                                      />
                                    </>
                                    :
                                    <div className="nc-NcImage flex aspect-w-11 aspect-h-11 w-full h-0">
                                      <div className={`flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                                        <div className="h-2/4 max-w-[50%]">
                                          <PlaceIcon />
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>

                                <Link
                                  onClick={close}
                                  className="absolute inset-0"
                                  to={"/product-detail"}
                                />
                              </div>

                              <div className="ml-4 flex flex-1 flex-col">
                                <div>
                                  <div className="flex justify-between">
                                    <div>
                                      <h3 className="text-base font-medium max-w-[90%]">
                                        <Link onClick={close} to={"/product-detail/" + cart?.item[itemId]?.itemRef.slug}>
                                          {cart?.item[itemId].name}
                                        </Link>
                                      </h3>

                                      {cart?.item[itemId]?.itemRef?.properties &&
                                        <div className="mt-2">
                                          {Object.keys(cart?.item[itemId]?.itemRef?.properties).map((propertie: any, index: number) => (
                                            <div key={index} className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                                              <div><span className="capitalize">{propertie}</span>: {cart?.item[itemId]?.itemRef?.properties[propertie]}</div>
                                            </div>
                                          ))}
                                        </div>
                                      }
                                    </div>

                                    {cart?.item[itemId]?.itemRef?.rebatedPrice
                                      ? <div className="flex flex-row">
                                        <Prices
                                          contentClass="py-1 px-1 md:py-1.5 md:px-3 text-lg font-semibold whitespace-nowrap"
                                          price={cart?.item[itemId]?.itemRef?.rebatedPrice}
                                        />

                                        <Prices
                                          className="text-[red]"
                                          contentClass="py-1 md:py-1.5 ml-2 md:px-3 text-lg font-semibold line-through text-red-500 whitespace-nowrap"
                                          price={cart?.item[itemId]?.itemRef?.price}
                                        />
                                      </div>

                                      : <Prices
                                        contentClass="py-1 md:py-1.5 md:px-3 text-lg font-semibold whitespace-nowrap"
                                        price={cart?.item[itemId]?.itemRef?.price}
                                      />
                                    }

                                  </div>
                                </div>
                                <div className="flex flex-1 items-end justify-between text-sm">
                                  <p className="text-gray-500 dark:text-slate-400">Quantité: x{cart?.item[itemId].quantity}</p>

                                  <div className="flex">
                                    <button
                                      onClick={() => handleDeleteProductInCart(cart?.item[itemId]?.itemId)}
                                      type="button"
                                      className="hover:underline font-medium text-primary-6000 dark:text-primary-500 "
                                    >
                                      <span>{t("Remove")}</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                          ))}
                        </>
                      }

                    </div>
                  </div>
                  <div className="bg-neutral-50 dark:bg-slate-900 p-5">

                    {Object.keys(cart).length !== 0 &&
                      <>
                        {cart?.cartLength !== 0 &&
                          <p className="flex justify-between font-semibold text-slate-900 dark:text-slate-100">
                            <span>
                              <span>Total de la commande</span>
                              <span className="block text-sm text-slate-500 dark:text-slate-400 font-normal">
                                Livraison et taxes calculées dans le tunnel d'achat.
                              </span>
                            </span>
                            <span className="whitespace-nowrap">{cart?.cartPrice?.toFixed(2)} Є</span>
                          </p>
                        }
                      </>
                    }

                    {cart?.cartLength === 0 &&
                      <h1 className="text-slate-500 text-[.9rem]">Votre panier est vide.</h1>
                    }

                    {Object.keys(cart).length === 0 &&
                      <h1 className="text-slate-500 text-[.9rem]">Votre panier est vide.</h1>
                    }



                    <div className="flex space-x-2 mt-5">

                      <ButtonSecondary
                        className="flex-1 border border-slate-200 dark:border-slate-700"
                        onClick={() => handleRedirectToCart(close)}
                      >
                        Voir le panier
                      </ButtonSecondary>

                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
