import { FC, useEffect, useState } from "react";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { getUserInfos, updateUserInfos } from "actions/user";
import { User } from "models/User";
import { useDispatch } from "react-redux";
import MySwitch from "components/MySwitch";
import AutoComplete from "react-google-autocomplete";
import { GOOGLE_MAP_API } from "../../config/key";
import { NotificationManager } from 'react-notifications';
import { Link } from "react-router-dom";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState<User>({ email: "" });
  const [billingAddress, setBillingAddress]: any = useState([]);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(getUserInfos()).then((response: any) => {
        setUser({ ...user, ...response });
      })
    };

    fetch();
  }, []);

  useEffect(() => {
    if (billingAddress) {
      let result: any = {}

      billingAddress?.address_components?.map((it: any) => {
        if (it.types[0] === "country") {
          result['country'] = it.long_name
        }
        if (it.types[0] == "locality") {
          result['city'] = it.long_name
        }
        if (it.types[0] == "postal_code") {
          result['postalCode'] = it.long_name
        }

        result['address'] = billingAddress?.formatted_address?.split(',')[0]

        if (billingAddress?.geometry) {
          result['lat'] = billingAddress?.geometry?.location.lat();
          result['long'] = billingAddress?.geometry?.location.lng();
        }

      })

      setUser({ ...user, ...{ billingAddress: result } })
    }
  }, [billingAddress])

  const handleUpdateAccount = async () => {
    let updatedUser = user

    if (!updatedUser.phone?.match('^[+]{1}[0-9]{1,3}[ ]{1}[0-9]{9}$')) {
      updatedUser.phone = undefined
      NotificationManager.error('Votre numéro de téléphone est invalide !', '')
      return
    }

    await dispatch(updateUserInfos(updatedUser)).then((response: any) => {
      setUser({ ...user, ...response });
      NotificationManager.success('Votre compte à bien été modifier !', '')
    })
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Mon profil - Bières de Ré</title>
      </Helmet>

      <div className="">
        <div className={`container py-14`}>
          <div className={`flow-root`}>
            <div className="flex font-semibold justify-center flex-col lg:px-28">
              <div className="flex flex-row gap-1 font-[500] text-[13px]">
                <a href="/" className="text-primary-6000 hover:underline">Accueil</a>
                <span>{">"}</span>
                <a href="">Mon profil</a>
              </div>
              <div className="mt-16 mb-16">
                <div className="flex w-full">
                  <div className="text-2xl sm:text-3xl mb-8 font-semibold flex flex-row">
                    <Link to="/customer/overview" className="w-10 h-7 relative top-[.1rem] flex items-center justify-center rounded bg-slate-200 opacity-[.6] hover:opacity-[1] text-neutral-700 nc-shadow-lg ">
                      <span className="text-[20px] font-[500]">{"<"}</span>
                    </Link>
                    <h1 className="ml-4">Mon profil</h1>
                  </div>
                </div>
                <div>
                  <div className="flex-grow mt-2 md:mt-0 md:pl-16 space-y-6 items-center">
                    <div className="grid grid-cols-2 gap-6">
                      <label className="block">
                        <Label>Nom</Label>
                        <Input
                          type="text"
                          value={user.name ? user.name : ""}
                          onChange={((e) => { setUser({ ...user, ...{ name: e.target.value } }) })}
                          className="mt-1"
                        />
                      </label>
                      <label className="block">
                        <Label>Prénom</Label>
                        <Input
                          type="text"
                          value={user.lastname ? user.lastname : ""}
                          onChange={((e) => { setUser({ ...user, ...{ lastname: e.target.value } }) })}
                          className="mt-1"
                        />
                      </label>
                    </div>
                    <div>
                      <Label>Adresse email</Label>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <i className="text-2xl las la-envelope"></i>
                        </span>
                        <Input
                          className="!rounded-l-none cursor-not-allowed"
                          value={user.email ? user.email : ""}
                          // onChange={((e) => { setUser({ ...user, ...{ email: e.target.value } }) })}
                          disabled
                        />
                      </div>
                    </div>
                    <div>
                      <Label>Adresse</Label>
                      <AutoComplete
                        apiKey={GOOGLE_MAP_API}
                        onPlaceSelected={(place: any) => {
                          setBillingAddress(place)
                        }}
                        options={{
                          types: ["geocode", "establishment"],
                        }}
                        placeholder="Saisissez une adresse"
                        defaultValue={user?.billingAddress?.address}
                        className='block w-full border-[1px] border-neutral-200  focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5'
                      />
                    </div>
                    <div>
                      <Label>Numéro de téléphone</Label>
                      <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                          <i className="text-2xl las la-phone-volume"></i>
                        </span>
                        <Input
                          className="!rounded-l-none"
                          placeholder="+33"
                          onChange={((e) => setUser({ ...user, ...{ phone: e.target.value } }))}
                          defaultValue={user.phone}
                        />
                      </div>
                    </div>
                    <div>
                      <MySwitch
                        label="Offre Partenaire"
                        desc="Inscrivez-vous à nos offres partenaires"
                        enabled={user.partnerOffer}
                        onChange={() => { setUser({ ...user, ...{ partnerOffer: !user.partnerOffer } }) }}
                      />
                    </div>
                    <div>
                      <ButtonPrimary onClick={() => (handleUpdateAccount())}>Modifier</ButtonPrimary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
