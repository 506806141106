
import { Helmet } from "react-helmet";
import i1 from "../../images/i1.png";
import i2 from "../../images/i2.png";
import i3 from "../../images/i3.png";
import i4 from "../../images/i4.png";
import { Link } from "react-router-dom";

const LaBrasserie = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>La brasserie - Bières de Ré</title>
            </Helmet>

            <div className="bg-[#cfedff] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16">
                    <Link to="/" className="underline cursor-pointer font-[500]">Accueil</Link>
                    <span className="ml-2 mr-2">{">"}</span>
                    <h1>La brasserie</h1>
                </div>
            </div>

            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-8">

                            <div>
                                <h1 className="text-[#725E39] font-semibold text-[40px]">La brasserie</h1>
                            </div>

                            <div className="mt-10">
                                <h1 className="text-[32px] text-[#7c7d7d]">Son histoire</h1>
                            </div>

                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 md:lg:grid-cols-2 xl:grid-cols-2 mt-10">
                                <div>
                                    <img className="w-full" src={i1} />
                                </div>

                                <div className="text-[14px] leading-[25px]">
                                    <p>C'est un brasseur d'origine Autrichienne qui est à l'origine de la brasserie Bières de Ré. Après des vacances sur l'île, il tombe sous le charme des lieux et décide de s'installer. Il y fonde la brasserie. Il amène dans ces bagages tout son savoir-faire et sa passion pour le brassage de la bière. Il apporte avec lui ses propres levures pour créer la première bière blanche brassée sur l'Île de Ré.</p>
                                    <p className="mt-3">C'est en 2011 que la brasserie change de propriétaire. Elle est rachetée par la famille Bertrand. C'est une grande famille de Viticulteurs et distillateurs qui sont implantés en Charente-Maritime depuis le milieu du XIXe. Elle va apporter une touche de modernité par de nouveaux outils de production mais aussi de nouvelles recettes comme la « Blanche de Ré ».</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 md:lg:grid-cols-2 xl:grid-cols-2 mt-10">
                                <div className="text-[14px] leading-[25px] flex items-center">
                                    <p>C'est aujourd'hui une équipe de 2 brasseurs qui ont à coeur de produire des bières authentiques, de qualité, tout en gardant l’esprit artisanal de la brasserie. Au fil des années, la gamme de bières s'est agrandie avec des classiques traditionnelles et des créations plus audacieuses comme la bière au miel de Ré, la Triple et ses trois houblons, la Déferlante et sa saveur très marquée, l'Indian Pale Ale ou encore les bières aromatisées au Cognac ou aux agrumes.</p>
                                </div>
                                <div>
                                    <img className="w-full" src={i2} />
                                </div>
                            </div>

                            <div className="mt-16">
                                <h1 className="text-[32px] text-[#7c7d7d]">Une sélection des matières premières ...</h1>
                            </div>

                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 md:lg:grid-cols-2 xl:grid-cols-2 mt-10">
                                <div>
                                    <img className="w-full" src={i3} />
                                </div>

                                <div className="text-[14px] leading-[25px] flex items-center">
                                    <p>Malgré un fort développement les Bières de Ré n'ont cessé de garder comme fil conducteur, le fort ancrage local inscrit dans ces gènes. Pour cela, en plus <Link to="/page/nos-engagements" className="underline hover:text-[#ed9d9f]">d'une production locale</Link> ils s'appliquent à utiliser des matières premières de qualité, régionales ou françaises de préférence. Le circuit court est fortement privilégié. Ils proposent aussi de vendre directement à la brasserie. N'hésitez pas à leur rendre visite pendant vos vacances. Toute l’équipe de Bières de Ré est ravie de vous accueillir dans un espace de convivialité pour partager leurs passions.</p>
                                </div>
                            </div>

                            <div className="mt-16">
                                <h1 className="text-[32px] text-[#7c7d7d]">Soutenons nos brasseurs indépendants</h1>
                            </div>

                            <div className="text-[14px] leading-[25px]">
                                <p className="mt-6">Le circuit court "Brasseurs Indépendants" est une marque collective mettant en avant le savoir-faire des brasseurs indépendants en les distinguant des bières industrielles. Elle permet de guider le consommateur dans l'identification de la véritable origine de sa bière en toute transparence.</p>
                                <p className="mt-4">Il est donc ainsi normal que la brasserie de Bière de Ré fasse partie de cette marque. <br />Dix critères sont mis en place pour bénéficier de la marque « Brasseur Indépendant » :</p>
                                <ul className="list-disc ml-8 mt-4">
                                    <li>Être producteur de bière et être une petite brasserie indépendante</li>
                                    <li>La brasserie doit être située en France</li>
                                    <li>La production ne doit pas être sous-traitée</li>
                                    <li>Le nom et l’adresse du brasseur doivent être clairement indiqués sur les étiquettes</li>
                                    <li>La composition du produit est clairement inscrite sur l'étiquette</li>
                                    <li>Le produit est fabriqué uniquement à base d’ingrédients naturels ou d’origine naturelle</li>
                                    <li>Aucun additif artificiel (conservateur, colorant, etc.) ou arôme artificiel ne doit être utilisé dans la bière</li>
                                    <li>Respect de la réglementation relative à l’hygiène et à la sécurité alimentaire en vigueur</li>
                                    <li>Engagement dans une démarche de qualité micro-biologique des bières</li>
                                    <li>Respect de l’ensemble de la réglementation en vigueur en France</li>
                                </ul>
                                <p className="mt-4">Cette charte garantie la qualité des produits et un savoir-faire artisanal fortement privilégié. Ils proposent aussi de vendre directement à la brasserie. N'hésitez pas à leur rendre visite pendant vos vacances. Toute l’équipe de Bières de Ré est ravie de vous accueillir dans un espace de convivialité pour partager leurs passions.</p>

                            </div>


                            <div className="mt-6">
                                <img src={i4} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default LaBrasserie;
